
export default {
    state: {
        userInfo: {},
		token:''
    },
    getters: {

    },
    mutations: {
        setUserInfo: (state, data) => {
            state.userInfo = data
        }
    },
    actions: {
    }
}
  