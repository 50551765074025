import request from '@/util/request'

// 登录
export function login (data) {
  return request({
    url: '/admin/v1/login',
    method: 'POST',
    data
  })
}

// 退出
export function logout (data) {
  return request({
    url: '/admin/v1/logout',
    method: 'POST',
    data
  })
}

// 获取省市区
export function getCity (params) {
  return request({
    url: '/admin/v1/city',
    method: 'get',
    params
  })
}

// 获取分类
export function allCate (params) {
  return request({
    url: '/admin/v1/allCate',
    method: 'get',
    params
  })
}

// 获取标签
export function getTags (params) {
  return request({
    url: '/admin/v1/tags',
    method: 'get',
    params
  })
}

// 获取七牛云上传token
export function getUploadToken (params) {
  return request({
    url: '/admin/v1/getUploadToken',
    method: 'get',
    params
  })
}
//用户列表
export function memberList (params) {
  return request({
    url: '/admin/v1/member/memberList',
    method: 'get',
    params
  })
}
// 用户详情
export function memberDetail (params) {
  return request({
    url: '/admin/v1/member/detail',
    method: 'get',
    params
  })
}
// 获取用户的关注列表
export function focusOrg (params) {
  return request({
    url: '/admin/v1/member/focusOrg',
    method: 'get',
    params
  })
}
// 分享列表
export function shareList (params) {
  return request({
    url: '/admin/v1/member/shareList',
    method: 'get',
    params
  })
}
//点赞日志
export function memberVoteList (params) {
  return request({
    url: '/admin/v1/member/voteList',
    method: 'get',
    params
  })
}
//抵佣金设置
export function marketingTask (params) {
  return request({
    url: '/admin/v1/marketing/task',
    method: 'get',
    params
  })
}
//封停用户
export function closure (data) {
  return request({
    url: '/admin/v1/member/closure',
    method: 'POST',
    data
  })
}
//用户观看记录
export function watchLog (params) {
  return request({
    url: '/admin/v1/member/watchLog',
    method: 'GET',
    params
  })
}
//用户观看记录详情
export function watchLogDetail (params) {
  return request({
    url: '/admin/v1/member/watchLogDetail',
    method: 'GET',
    params
  })
}

