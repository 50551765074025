import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'

Vue.use(Vuex)
// 用户信息
// 考试信息
// 任务类型
// 创建评价任务弹窗状态
const store = new Vuex.Store({
    modules: {
        common
    }
})

export default store
