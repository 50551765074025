// 常用方法封装
export default {
	formatDate(time, nosec) {
		if(!time){ // 为空时统一处理
			return '--'
		}
		var date = new Date(time);
		var year = date.getFullYear(),
			month = date.getMonth() + 1,
			day = date.getDate(),
			hour = date.getHours(),
			min = date.getMinutes(),
			sec = date.getSeconds();
		var newTime = year + '-' +
			(month < 10 ? '0' + month : month) + '-' +
			(day < 10 ? '0' + day : day) + ' ' +
			(hour < 10 ? '0' + hour : hour) + ':' +
			(min < 10 ? '0' + min : min);
		nosec ? '' : newTime = newTime + ':' + (sec < 10 ? '0' + sec : sec)
		return newTime
	},
}
