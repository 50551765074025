import { getCity } from '@/api/base'
let qnUpdata = 'https://up-z1.qiniup.com' // 七牛云上传图片地址
let qnUrl = 'https://spyun.pingeban.com' // 七牛云图片地址
let maxHeight = 2000

let orderStatus = { // 订单状态
	1: '下单未支付',
	2: '已支付',
	3: '已核销',
	4: '已退款',
	5: '已取消',
	6: '拼团进行中',
	7: '拼团成功',
}

let orderType = { // 订单类型
	1: '课程订单',
	2: '优惠券订单',
	3: '拼团订单'
}

let collegeType = {
	1: '金刚区',
	2: '场景区',
	3: '视频区'
}

// 获取城市列表
function getcity(id) {
	return getCity({
		parent_id: id || 100000
	}).then((res) => {
		return res.data.map(item => {
			item.leaf = id ? true : false
			return item
		})
	}).catch((err) => {
		return err
	})
}

export default {
	qnUpdata,
	qnUrl,
	maxHeight,
	orderStatus,
	orderType,
	collegeType,
	getcity,
}
