import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './util/filters.js'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import  * as Echarts from 'echarts';

Vue.use(ElementUI);
Vue.prototype.$echarts = Echarts

Vue.config.productionTip = false

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'jDzjBQCDRQVgz3xFMn338oFPIPnuvl1E'
});

import constant from './util/constant';
import common from './util/common.js';
Vue.prototype.$constant = constant
Vue.prototype.$common = common
// 注册全局过滤器
Object.keys(filters).forEach(key=> {
    Vue.filter(key, filters[key])
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
