
// 防抖
function debounce(fn, wait = 1000) {
    var timeout = null;      //定义一个定时器
    return function() {
        if(timeout !== null) 
                clearTimeout(timeout);  //清除这个定时器
        timeout = setTimeout(fn, wait);  
    }
}

// 节流
function throttle(fn, delay = 1000) {
    var timeout = true;      //定义一个定时器
    return function() {
        if(!timeout) {
            return
        }
        timeout = false;
        setTimeout(()=>{
            fn()
            timeout = true;
        }, delay)
    }
}

// 获取当前表格最高高度
function setHeight(dom, num) {
    let clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    if(dom && dom.length) { // 减去不固定元素高度
        let height = 0
        dom.forEach(item => {
            height += item.clientHeight
        })
        return clientHeight - num - height
    }
    return clientHeight - num
}

// 获取页面筛选时间
function setSearchTime(time) {
    return [
        time && time.length > 0 ? time[0].getTime()/1000 : null,
        time && time.length > 0 ? ( time[1].getTime()/1000 ) +24*60*60-1 : null,
    ]
}

export default {
    debounce,
    throttle,
    setHeight,
    setSearchTime
}