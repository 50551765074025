let route = [
    /***************** 
     * 用户管理***************/
    {  // 用户列表
        path: '/user/userlist',
        name: 'Userlist',
        component: resolve => require(['../views/user/userlist.vue'], resolve),
        // component: () => import('../views/user/userlist.vue')
    },
    {  // 用户详情
        path: '/user/userDetail',
        name: 'userDetail',
        component: resolve => require(['../views/user/userDetail/index.vue'], resolve),
    },
    // 分享列表
    {
        path: '/user/shareList',
        name: 'shareList',
        component: resolve => require(['../views/user/shareList.vue'], resolve),
    },
    /***************** 
     * 课程和订单***************/
    { // 试听课列表
        path: '/courseOrder/stkList',
        name: 'stkList',
        component: resolve => require(['../views/courseOrder/stkList.vue'], resolve),
    },
    { // 订单列表
        path: '/courseOrder/orderList',
        name: 'OrderList',
        component: resolve => require(['../views/courseOrder/orderList.vue'], resolve),
    },
    { // 拼团跟踪
        path: '/courseOrder/groupTracking',
        name: 'GroupTracking',
        component: resolve => require(['../views/courseOrder/groupTracking'], resolve),
    },
    /*********
     * 视频***************/
    { // 试听课视频库
        path: '/video/stvideolist',
        name: 'Stvideolist',
        component: resolve => require(['../views/video/stvideolist.vue'], resolve),
    },
    { // 问答管理
        path: '/video/qaManage',
        name: 'QaManage',
        component: resolve => require(['../views/video/qaManage.vue'], resolve),
    },
    {  // 点赞日志
        path: '/video/likeLog',
        name: 'LikeLog',
        component: resolve => require(['../views/video/likeLog.vue'], resolve),
    },
    { // 视频添加虚拟点赞
        path: '/video/virtualLike',
        name: 'VirtualLike',
        component: resolve => require(['../views/video/virtualLike.vue'], resolve),
    },
    { // 虚拟点赞
        path: '/video/robotLike',
        name: 'RobotLike',
        component: resolve => require(['../views/video/robotLike.vue'], resolve),
    },
    /***************** 
     * 机构管理***************/
    //机构列表
    {
        path: '/institutional',
        name: 'Institutional',
        component: resolve => require(['../views/institutional/list.vue'], resolve),
    },
    //机构详情
    {
        path: '/institutional/org_detail',
        name: 'InstitutionalDetail',
        component: resolve => require(['../views/institutional/detailList/detail_list.vue'], resolve),
    },
    //员工管理
    {
        path: '/institutional/staff',
        name: 'Staff',
        component: resolve => require(['../views/institutional/staff.vue'], resolve),
    },
    // 邀请注册记录
    {
        path: '/institutional/invite',
        name: 'Invite',
        component: resolve => require(['../views/institutional/invite.vue'], resolve),
    },
    // 积分兑换奖品设置
    {
        path: '/institutional/pointsPrize',
        name: 'PointsPrize',
        component: resolve => require(['../views/institutional/pointsPrize.vue'], resolve),
    },
    /***************** 
     * 分类和标签***************/
    {
        path: '/classTag/classification',
        name: 'Classification',
        component: resolve => require(['../views/classTag/classification.vue'], resolve),
    },
    {
        path: '/classTag/tagLibrary',
        name: 'TagLibrary',
        component: resolve => require(['../views/classTag/tagLibrary.vue'], resolve),
    },
    {
        path: '/classTag/InterestSettings',
        name: 'TagLibrary',
        component: resolve => require(['../views/classTag/InterestSettings.vue'], resolve),
    },
    /***************** 
     * 数据分析***************/
    {
        path: '/dataAnalysis/summary',
        name: 'DataSummary',
        component: resolve => require(['../views/dataAnalysis/summary.vue'], resolve),
    },
    {
        path: '/dataAnalysis/day',
        name: 'DataAnalysisDay',
        component: resolve => require(['../views/dataAnalysis/day.vue'], resolve),
    },
    {
        path: '/dataAnalysis/course',
        name: 'DataAnalysisCourse',
        component: resolve => require(['../views/dataAnalysis/course.vue'], resolve),
    },
    {
        path: '/dataAnalysis/video',
        name: 'DataAnalysisVideo',
        component: resolve => require(['../views/dataAnalysis/video.vue'], resolve),
    },
    {
        path: '/dataAnalysis/organization',
        name: 'DataAnalysisOrg',
        component: resolve => require(['../views/dataAnalysis/organization.vue'], resolve),
    },
    /***************** 
     * 试听课营销活动***************/
    {
        path: '/marketing/creditSetting',
        name: 'CreditSetting',
        component: resolve => require(['../views/marketing/creditSetting.vue'], resolve),
    },
    {
        path: '/marketing/creditDetail',
        name: 'CreditDetail',
        component: resolve => require(['../views/marketing/creditDetail.vue'], resolve),
    },
    {
        path: '/marketing/sendadsSetting',
        name: 'SendadsSetting',
        component: resolve => require(['../views/marketing/sendadsSetting.vue'], resolve),
    },
    {
        path: '/marketing/sendadsDetail',
        name: 'SendadsDetail',
        component: resolve => require(['../views/marketing/sendadsDetail.vue'], resolve),
    },
    {
        path: '/marketing/likeActivity',
        name: 'LikeActivity',
        component: resolve => require(['../views/marketing/likeActivity.vue'], resolve),
    },
    /***************** 
     * 优惠券***************/
    {
        path: '/coupon/list',
        name: 'CouponList',
        component: resolve => require(['../views/coupon/list.vue'], resolve),
    },
    {
        path: '/coupon/order',
        name: 'CouponOrder',
        component: resolve => require(['../views/coupon/order.vue'], resolve),
    },
    /***************** 
     * 学院分类***************/
    {
        path: '/college/classEdit',
        name: 'ClassEdit',
        component: resolve => require(['../views/college/classEdit.vue'], resolve),
    },
    {
        path: '/college/articleList',
        name: 'articleList',
        component: resolve => require(['../views/college/articleList.vue'], resolve),
    },
    {
        path: '/college/homeEdit',
        name: 'HomeEdit',
        component: resolve => require(['../views/college/homeEdit.vue'], resolve),
    },
    /***************** 
  * 账号设置***************/
    {
        path: '/accountset/manage',
        name: 'accountsetManage',
        component: resolve => require(['../views/accountset/manage.vue'], resolve),
    },

]
export default route