import Vue from 'vue'
import VueRouter from 'vue-router'
import listPage from './listPage'

console.log(listPage)
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: resolve => require(['../views/login.vue'], resolve),
        // component: () => import('../views/login.vue')
    },
    {
        path: '/layout',
        name: 'layout',
        component: resolve => require(['../views/layout.vue'], resolve),
        // component: () => import('../views/layout.vue'),
        children: listPage
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 路由导航冗余报错（路由重复）
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router
